import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import TopNav from "../Components/TopNav";
import ServiceCard from "../Components/ServiceCard";
import Aos from "aos";
import "aos/dist/aos.css";
import { faAt, faPhone } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Components/Loader";
import Footer from "../Components/Footer";
import ContactForm from "../Components/ContactForm";
import MbNav from "../Components/MbNav";

function Main() {
  const [mbNav, setMbNav] = useState(false);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    document.querySelector(".loaderContainer").classList.add("hidden");
  }, []);

  return (
    <>
      <Loader />
      {mbNav && <MbNav setNav={setMbNav} />}
      <header>
        <TopNav setNav={setMbNav} />
      </header>
      <main className="">
        <section
          id="inicio"
          className="relative min-h-screen text-white overflow-hidden"
        >
          {/* Background Image with Overlay */}
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-black/40"></div>
            <img
              src="./mainBg.jpeg"
              className="w-full h-full object-cover"
              alt="Profesionales contables en oficina"
            />
            {/* Background Pattern */}
            <div className="absolute inset-0 opacity-10">
              <div className="absolute inset-0" style={{
                backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'none\' fill-rule=\'evenodd\'%3E%3Cg fill=\'%23ffffff\' fill-opacity=\'0.1\'%3E%3Cpath d=\'M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z\'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")'
              }}></div>
            </div>
          </div>

          {/* Content */}
          <div className="container mx-auto px-4 py-20 relative z-10 min-h-screen flex items-center">
            <div className="max-w-3xl mx-auto text-center">
              <div className="space-y-8" data-aos="fade-up">
                <div className="flex flex-col items-center gap-4">
                  <span className="text-white text-lg font-medium">Mi contador CR</span>
                  <h1 className="text-4xl md:text-5xl font-bold leading-tight text-white">
                    Su asesor estratégico en Servicios Contables Profesionales y Tributarios
                  </h1>
                </div>
                
                <div className="space-y-4">
                  <p className="text-white/90 text-xl text-shadow: 0 1px 0 rgb(0 0 0 / 40%) font-medium">
                    Somos un equipo a la medida de sus necesidades.
                    Queremos darle el soporte Contable que su negocio necesita para el crecimiento financiero, 
                    le asistimos en sus declaraciones Tributarias.
                  </p>
                </div>

                <div className="flex flex-wrap justify-center gap-4">
                  <a href="#contacto" className="bg-white text-gray-900 px-8 py-3 rounded-lg font-semibold hover:bg-gray-50 transition-colors">
                    Contáctenos
                  </a>
                  <a href="#servicios" className="border-2 border-white text-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
                    Nuestros Servicios
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="mision"
          className="objetivos main-bg py-12 h-fit flex gap-16 px-14"
          data-aos="fade-up"
        >
          <div
            className="left  w-1/2 flex justify-center"
            data-aos="fade-right"
          >
            <img
              src="./MISION.webp"
              className="w-full h-full rounded-2xl"
              alt="imagen principal, personas en una oficina"
            />
          </div>

          <div className="right w-1/2" data-aos="fade-left">
            <div className="title w-full flex gap-8 items-center">
              <img src="./Bell.png" alt="" className="icon" />
              {/* <FontAwesomeIcon className="big-txt white-txt" icon={faLightbulb} /> */}
              <h2 className="fs-24 bolder">
                Nuestra <br /> <h2 className="white-txt">Misión</h2>
              </h2>
            </div>

            <p className="fs-16 mt-8 white-txt">
              Ser su aliado estratégico, ayudarle a implementar un crecimiento
              ordenado y sostenible que le permita desarrollarse con
              tranquilidad y Libertad Financiera Contable, sabiendo que se
              encuentra en buenas manos. Estamos juntos en un encuentro de ideas
              inteligentes para plantear soluciones inteligentes a sus
              necesidades, únase a la Campana de la Libertad Financiera,
              solidarios para construir una Costa Rica mejor.
            </p>

            <a href={"#servicios"}>
              <button className="white-bg rounded main-txt bold py-4 px-6 mt-4 w-40">
                Servicios
              </button>
            </a>
          </div>
        </section>

        <section id="servicios" className="servicios h-fit   h-fit w-full">
          <div
            data-aos="fade-down"
            className="title relative flex justify-center items-center  w-full"
          >
            <div className="opacity absolute w-full h-full"></div>
            <div className="w-full absolute flex flex-col items-center justify-center h-full">
              <div className="flex items-center">
                <img src="./bell.png" alt="" className="icon " />

                <span className="big-txt white-txt bolder">Servicios</span>
              </div>
              <h2 className=" white-txt bold light px-4 text-center mb-fs-12">
                Somos su herramienta externa respaldada por un grupo de
                profesionales en Contaduría Pública
              </h2>
            </div>
          </div>

          <div className="serviceCardsContainer h-fit px-14 w-full mt-14 flex flex-wrap mb-flex-col gap-12">
            <div
              className="flex cardGroup flex-col gap-4 h-full"
              data-aos="zoom-in"
            >
              <ServiceCard
                title={"Nuestros servicios contables"}
                description={
                  "Están respaldados por un grupo de profesionales en Contaduría Pública"
                }
              />
              <ServiceCard
                title={"Entorno empresarial"}
                description={
                  "Le asesoramos en los factores internos y externos que inflyen en la economía de su empresa"
                }
              />
              <ServiceCard
                title={"Servicios Outsourcing"}
                description={
                  "Somos una herramienta externa a la medida de sus necesidades"
                }
              />

              <div className="serviceCard  w-[450px]">
                <div className="top flex items-center overflow-hidden  h-fit ">
                  <div className="cardTitle  h-full flex items-center">
                    <span className="main-txt h-fit bolder">
                      Certificaciones de:
                    </span>
                  </div>
                </div>

                <div className="body  light fs-16">
                  <ul>
                    <li>Estados Financieros</li>
                    <li>Flujos de caja proyectados</li>
                    <li>Estudios especiales</li>
                    <li>Estudios de factibilidad</li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="flex cardGroup flex-col gap-4 h-full"
              data-aos="zoom-in"
            >
              <ServiceCard
                title={"Trámites ante la Administración Tributaria"}
                description={
                  "Le asistimos en los procesos de inscripción como contribuyente y en sus declaraciones fiscales"
                }
              />
              <ServiceCard
                title={"Declaración del Impuesto del Valor Agregado D104-2"}
                description={
                  "En su delaración del impuesto al IVA nos aseguramos de que obtenga sus créditos fiscales"
                }
              />
              <ServiceCard
                title={"Declaración de Rentas del Capital Inmobiliario D125"}
                description={
                  "Le ayudamos en su declaración de ingresos de Propiedad de Bienes Inmuebles"
                }
              />
            </div>

            <div
              className="flex flex-col cardGroup gap-4 h-full"
              data-aos="zoom-in"
            >
              <ServiceCard
                title={"Declaración del Impuesto a la Renta D101"}
                description={
                  "Le asistimos en su declaración del impuesto a la renta, nos aseguramos de que obtenga sus créditos fiscales"
                }
              />

              <ServiceCard
                title={"Declaraciones Sociedades Inactivas D195"}
                description={
                  "Realizamos sus declaraciones de sociedades inactivas."
                }
              />

              <ServiceCard
                title={
                  "Declaración del Registro de Transparencia y Beneficiarios Finales (RTBF)"
                }
                description={
                  "Le asistimos en su declaración ante el Banco Central de Costa Rica"
                }
              />
            </div>
          </div>
        </section>

        <section
          id="contacto"
          className="contacto h-fit mt-32 flex flex-col items-center justify-center "
        >
          <ContactForm />

          <div
            data-aos="fade-right"
            className="contactCards main-bg w-full flex flex-wrap justify-center gap-12 mt-16"
          >
            <div className="contactCard main-bg p-8 w-fit flex items-center flex-col gap-2 rounded-xl">
              <FontAwesomeIcon className="white-txt big-txt" icon={faPhone} />
              <p className="white-txt bold">(506) 8454-0000</p>
            </div>

            <div className="contactCard main-bg p-8 w-fit flex items-center flex-col gap-2 rounded-xl">
              <FontAwesomeIcon className="white-txt big-txt" icon={faAt} />
              <p className="white-txt fs-12 bold">
                contacto@contadorquedeclaro.com
              </p>
            </div>

            <a title="WhatsApp" className="white-txt bold fs-13" href=" https://wa.me/+50684540000" target="_blank">
              <div className="contactCard main-bg p-8 w-fit flex items-center flex-col gap-2 rounded-xl">
                <FontAwesomeIcon
                  className="white-txt big-txt"
                  icon={faWhatsapp}
                />
                Ir a Whatsapp
              </div>
            </a>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Main;